import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { testimonials as testimonialsData } from '../data/testimonials'
import { Layout, SEO } from '@global'
import { Hero, SocialProof, FiftyFifty, Testimonials } from '@layouts'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "home/Hero_Home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgOne: file(relativePath: { eq: "home/1_Home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgTwo: file(relativePath: { eq: "home/2_Home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgThree: file(relativePath: { eq: "home/3_Home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const testimonials = testimonialsData.slice(0, 3)

  return (
    <div className="relative">
      <SEO title="Home" />
      <Layout>
        <Hero
          fluid={data.hero.childImageSharp.fluid}
          eyebrow="indianapolis and surrounding areas"
          title="Legacy bathroom remodeling with integrity"
          ctaText="about us"
          ctaLink="/about/"
        />
        <SocialProof />
        <FiftyFifty
          fluid={data.imgOne.childImageSharp.fluid}
          body="You’re looking for a trustworthy remodeler who does quality work at a fair price. Remodeling is a big deal; we understand that! That’s why we ensure that you’re involved in every step of the process, so you can relax and enjoy your beautiful new bathroom."
          eyebrow="trust is key"
          heading="Hassle-free Remodeling"
          to="/services/"
          ctaText="Our Services"
          className="py-10 lg:mt-8"
        />
        <FiftyFifty
          fluid={data.imgTwo.childImageSharp.fluid}
          body="With over 60 years in business, we bring you the security of professionalism and integrity. Our reliable crews value and respect the time and home of each customer. This, along with clear communication and complete transparency, is an essential ingredient to a successful customer relationship."
          eyebrow="SINCE 1959"
          heading="Locally Owned and Operated"
          to="/about/"
          ctaText="Meet Us"
          className="py-10 lg:py-16"
          flip
        />
        <FiftyFifty
          fluid={data.imgThree.childImageSharp.fluid}
          body="You want someone to listen to your wants and bring the logistics to match. Our tried and true process ensures a materialized vision and the highest quality of material and workmanship to make it last."
          eyebrow="HOW WE WORK"
          heading="You Talk, We Listen"
          to="/services/"
          ctaText="Our Process"
          className="py-10 mb-12"
        />
        <Testimonials data={testimonials} />
      </Layout>
    </div>
  )
}
export default IndexPage
